import React, { useState } from 'react';
import { Container, Row, Col, CardGroup, Card, Button, Modal } from 'react-bootstrap'
import './styles.css'

import dev from '../../assets/img/Programing.png'
import design from '../../assets/img/daa.jpg'
import cloud from '../../assets/img/cyb2.jpg'
import cyb from '../../assets/img/cyb1.jpg'
import Tec from '../../assets/img/tec.jpg'
import Elec from '../../assets/img/elec.jpg'
import Footer from '../Footer';




const Services = () => {

  return (
    <div className="serv" id="servicos">
      <Container className="g-4">


        <h3 className="text-center">Nossos serviços</h3>
        <div class="borderS"></div>
        <CardGroup>

          <Card className="cardServices">
            <Card.Img variant="top" src={design} className="imgbox" />
            <div data-text="Design">

            </div>

            <div className="contentTxt">
              <div className="cardText">


                <label for="modal-1" class="btn"><h3>Design</h3></label>

              </div>

            </div>

          </Card>
          <Card className="cardServices">
            <Card.Img variant="top" src={dev} className="imgbox" />
            <div className="imgbox" data-text="Design">

            </div>

            <div className="contentTxt">
              <div className="cardText" >
                <label for="modal-2" class="btn"><h3>Programação</h3></label>

              </div>

            </div>




          </Card>
          <Card className="cardServices">
            <Card.Img variant="top" src={cyb} className="imgbox" />
            <div className="imgbox" data-text="Design">

            </div>

            <div className="contentTxt">
              <div className="cardText" >

                <label for="modal-3" class="btn"><h3>Cybersecurity</h3></label>

              </div>

            </div>


          </Card>
        </CardGroup>


        <CardGroup>
          <Card className="cardServices">
            <Card.Img variant="top" src={Tec} className="imgbox" />
            <div data-text="Design">

            </div>

            <div className="contentTxt">
              <div className="cardText">

                <label for="modal-4" class="btn"><h3>Engenharia informática</h3></label>

              </div>

            </div>


          </Card>
          <Card className="cardServices">
            <Card.Img variant="top" src={cloud} className="imgbox" />
            <div className="imgbox" data-text="Design">

            </div>

            <div className="contentTxt">
              <div className="cardText" >

                <label for="modal-5" class="btn"><h3>Cloud Computing</h3></label>

              </div>

            </div>


          </Card>
          <Card className="cardServices">
            <Card.Img variant="top" src={Elec} className="imgbox" />
            <div className="imgbox" data-text="Design">

            </div>

            <div className="contentTxt">
              <div className="cardText" >
                <label for="modal-6" class="btn"><h3>Electrônica</h3></label>

              </div>

            </div>


          </Card>

        </CardGroup>
        <div className="modal-Container">
          <input type="checkbox" id="modal-1" className="hs" />
          <div className="modal-wrapper">
            <div className="modalbox">
              <div className="modal-content">
                <div class="modal-header">
                  <h2>Design</h2>
                  <label for="modal-1" className="btn-close1">Fechar</label>
                </div>
                <div className="modal-content-body">

                  <p>
                    design significa projetar, design é um conceito amplo que
                    representa o processo de criação de produtos a partir da
                    excelência técnica e estética com o objetivo de solucionar
                    problemas e agregar valor, neste processo de pensamento a
                    Ematronics compreende a criação de produtos para solucionar
                    problemas, incluindo aspectos funcionais e estéticos.
                    No design gráfico usamos imagens, textos e ilustrações em função da comunicação visual, o designer gráfico é responsável por criar soluções visuais que vão desde logotipos até livros, outdoors, banners, anúncios etc...diferente do nosso web designer, que cria interfaces, páginas e aplicativos para a web, os termos UX (User Experience) e UI (User Interface) dizem respeito, respectivamente, à qualidade da interface e da interação, logo, o web design vai muito além da comunicação visual, trabalhando a própria relação do usuário com a interface.
                  </p>
                </div>

              </div>


            </div>

          </div>
          <input type="checkbox" id="modal-2" className="hs" />
          <div className="modal-wrapper">
            <div className="modalbox">
              <div className="modal-content">
                <div class="modal-header">
                  <h2>Programação</h2>
                  <label for="modal-2" className="btn-close1">Fechar</label>
                </div>
                <div className="modal-content-body">

                  <p>
                    programação é um processo de escrita, testes e
                    manutenção de programas de computadores. Esses
                    programas, por sua vez, são compostos por conjunto
                    de instruções determinados pelo programador que
                    descrevem tarefas a serem realizadas pela máquina
                    e atendem diversas finalidades. A Ematronics tem
                    varios quadros equipados de conhecimento em
                    diferentes tipos de linguagens de programacao,
                    pelo que é especializada no ramo de programaçao em:
                  </p>
                  <ul>
                    <li>Engenharia de software;</li>
                    <li>Desenvolvimento Web;</li>
                    <li>Aplicaçoes( web, mobile devices e Desktop</li>
                  </ul>
                </div>

              </div>


            </div>

          </div>
          <input type="checkbox" id="modal-3" className="hs" />
          <div className="modal-wrapper">
            <div className="modalbox">
              <div className="modal-content">
                <div class="modal-header">
                  <h2>Cybersecurity</h2>
                  <label for="modal-3" className="btn-close1">Fechar</label>
                </div>
                <div className="modal-content-body">

                  <p>
                    Cybersecurity(cibersegurança ) refere-se a segurança de
                    computadores  no que tange a proteção de sistemas de computador
                    contra roubo ou danos ao hardware, software ou dados eletrônicos,
                    bem como a interrupção ou desorientação dos serviços que fornecem.
                    Segurança da Informação compreende Segurança Cibernética, Segurança
                    Física, Segurança de Tecnologias de Informacao e Segurança de Pessoas.
                    Todas são usadas pelas empresas para proteger contra o acesso não
                    autorizado à informação, seja em Datacenter e sistemas locais ou em
                    nuvem e quaisquer outros sistemas computacionais.
                    Pilares e elementos da Cibersegurança:
                  </p>
                  <ul>
                    <li>Confidencialidade;</li>
                    <li>Integridade;</li>
                    <li>Disponibilidade;</li>
                    <li>Autenticidade;</li>
                    <li>Legalidade;</li>
                    <li>Auditabilidade e não repúdio;</li>
                    <li>Segurança de aplicativos</li>
                    <li>Segurança de cloud</li>
                    <li>Segurança de rede</li>
                    <li>Recuperação de desastres / planeamento de continuidade de negócios</li>
                    <li>Segurança operacional</li>
                    <li>Educação do usuário final</li>

                  </ul>
                </div>

              </div>


            </div>

          </div>
          <input type="checkbox" id="modal-4" className="hs" />
          <div className="modal-wrapper">
            <div className="modalbox">
              <div className="modal-content">
                <div class="modal-header">
                  <h2>Engenharia informatica</h2>
                  <label for="modal-4" className="btn-close1">Fechar</label>
                </div>
                <div className="modal-content-body">

                  <p>
                    Informática é a ciência que estuda a informação, com o
                    intuito de organizar, obter maior rapidez no processamento e
                    tornar mais seguras as informações geradas, este conceito mudou
                    o mundo para sempre. A palavra informática é a junção de duas
                    palavras, nomeadamente informação e automação, ou seja, informatica
                    é uma forma de obter informação de maneira automática.
                    A Ematronics é especializada em informatica virada a Software e/ou Hardware
                    tais como:

                  </p>
                  <ul>
                    <li>Comércio (fornecimento, montage manutencao de qualquer material informatico)</li>
                    <li>Didactica( Quadros formados e equipados de material para lecionar, treinar ou apoiar na tomada de decisao)</li>
                    <li>Empresarial (programas para controlo de bens e pessoas)</li>
                    <li>Gestão(Fornecimento de ERPs para gestao empresarial.i.e: PHC)</li>
                    <li>Inteligencia artificial</li>
                    <li>Engenharia de Software</li>
                    <li>Outros…</li>


                  </ul>
                </div>

              </div>


            </div>

          </div>
          <input type="checkbox" id="modal-5" className="hs" />
          <div className="modal-wrapper">
            <div className="modalbox">
              <div className="modal-content">
                <div class="modal-header">
                  <h2>Cloud Computing</h2>
                  <label for="modal-5" className="btn-close1">Fechar</label>
                </div>
                <div className="modal-content-body">

                  <p>
                    A computação em nuvem é a entrega de recursos de tecnologias
                    de informacao por meio da Internet. Em vez de comprar, ter e
                    manter datacenters e servidores físicos, você pode acessar
                    serviços de tecnologia, como capacidade computacional,
                    armazenamento e base de dados, conforme a necessidade, usando um
                    provedor de nuvem como a Amazon Web Services (AWS).
                    Componentes da Cloud Computing:

                  </p>
                  <ul>
                    <li>Infraestrutura como serviço (IaaS);</li>
                    <li>Plataforma como serviço (PaaS);</li>
                    <li>Software como serviço (SaaS);</li>

                  </ul>
                </div>

              </div>


            </div>

          </div>
          <input type="checkbox" id="modal-6" className="hs" />
          <div className="modal-wrapper">
            <div className="modalbox">
              <div className="modal-content">
                <div class="modal-header">
                  <h2>Electrônica </h2>
                  <label for="modal-6" className="btn-close1">Fechar</label>
                </div>
                <div className="modal-content-body">

                  <p>
                    Numa definição mais abrangente, podemos dizer que a eletrônica é o ramo
                    da ciência que estuda o uso de circuitos formados por componentes
                    elétricos e/ou eletrônicos, com o objetivo principal de representar,
                    armazenar, transmitir ou processar informações além do controle de
                    processos e mecanismos. A Ematronics usa a electronica como um
                    ramo da ciencia para solucionar problemas  analógico e/ou digital no
                    que concerne á:
                  </p>
                  <ul>
                    <li>Montagem de sistemas de segurança( CCTV, Alarmes, portões eléctricos, ElectroFence, Beemers, sensors, Relógios Digital para presenças, Cancelas, Lombas, Câmeras);</li>
                    <li>Reparação de dispositivos electrônicos(Laptops, Desktops, Printers, Celulares, Tablets, etc…);</li>
                    <li>Alarmes( Caseiros, Viaturas, Sirenes, etc...);</li>
                    <li>Televisão digital(Dstv, Startimes, Zap, GoTv etc…);</li>
                    <li>Tracking systems(GPS);</li>
                    <li>Locksmithing(Cars, Safes, Locks, etc...);</li>
                    <li>Recuperação de desastres / planeamento de continuidade de negócios</li>
                    <li>Segurança operacional</li>
                    <li>Educação do usuário final</li>
                  </ul>
                </div>

              </div>


            </div>

          </div>

        </div>























      </Container>








      <Footer />



    </div >



















  );
}

export default Services;