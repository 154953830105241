import React from 'react';

import { Container, CardGroup, Card } from 'react-bootstrap'

import Emanuel from '../../assets/ourImg/Ema.jpg'

import Gertides from '../../assets/ourImg/Ger.jpg'

import Ayad from '../../assets/ourImg/Aya.jpg'
import Nilton from '../../assets/ourImg/Nil.jpg'
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';


import './CardTeamStyle.css'
import Email from '@material-ui/icons/Email';

const CardsTeam = () => {
  return (
    <div className="coloc">
      <div class="container">
        <h1 className="text-center teamT">Our Team</h1>
        <input type="radio" name="dot" id="one" />
        <input type="radio" name="dot" id="two" />
        <div class="main-card">
          <div class="cards">
         
            <div class="card">
              <div class="content">
                <div class="img">
                  <img src={Emanuel} alt="" />
                </div>
                <div class="details">
                  <div class="name">Emanuel <span className="surname">Tonela</span> </div>
                  <div class="job">Ematronics Founder, Engenheiro Informatico & Information Technology Consultant</div>
                </div>
                <div class="media-icons">
                  <a href="#"><EmailIcon className="insIcon" /></a>
                  <a href="#"><FacebookIcon className="insIcon" /></a>
                  <a href="#"><InstagramIcon className="insIcon" /></a>
                  <a href="#"><TwitterIcon className="insIcon" /></a>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="content">
                <div class="img">
                  <img src={Gertides} alt="" />
                </div>
                <div class="details">
                  <div class="name">Gertides <span className="surname">Magaia</span> </div>
                  <div class="job">Master of Science in structural and geotechnical engineering & Engenheiro civil e de transportes</div>
                </div>
                <div class="media-icons">
                  <a href="#"><EmailIcon className="insIcon" /></a>
                  <a href="#"><FacebookIcon className="insIcon" /></a>
                  <a href="#"><InstagramIcon className="insIcon" /></a>
                  <a href="#"><TwitterIcon className="insIcon" /></a>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="content">
                <div class="img">
                  <img src={Ayad} alt="" />
                </div>
                <div class="details">
                  <div class="name">Ayad <span className="surname">Ossaile</span></div>
                  <div class="job">Software Engineering & UI/UX Designer</div>
                </div>

                <div class="media-icons">
                  <a href="#"><EmailIcon className="insIcon" /></a>
                  <a href="#"><FacebookIcon className="insIcon" /></a>
                  <a href="#"><InstagramIcon className="insIcon" /></a>
                  <a href="#"><TwitterIcon className="insIcon" /></a>
                </div>

              </div>
            </div>
          
        
          </div>
          <div class="cards">
            <div class="card">
              <div class="content">
                <div class="img">
                  <img src={Nilton} alt="" />
                </div>

                <div class="details">
                  <div class="name">Nilton <span className="surname">Macuacua</span>  </div>

                  <div class="job"> Graphic Designer & Project Manager</div>
                </div>
                <div class="media-icons">
                  <a href="#"><EmailIcon className="insIcon" /></a>
                  <a href="#"><FacebookIcon className="insIcon" /></a>
                  <a href="#"><InstagramIcon className="insIcon" /></a>
                  <a href="#"><TwitterIcon className="insIcon" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="button">
          <label for="one" className=" active one"></label>
          <label for="two" className="two"></label>
        </div>
      </div>




    </div>



  );
}

export default CardsTeam;