import React from 'react';
import { Contrainer, Row, Col, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import NedBank from '../../assets/img/ned1.png'
import Parm from '../../assets/img/Part.jpg'
import Curule from '../../assets/img/curule.png'
import Fonseca from '../../assets/img/fos.png'

import Til from '../../assets/img/til.png'



import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone'
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

import './styles.css'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="footer-col">
            <h4>Localize-nos</h4>
            <h6>
              Maputo-Moçambique<br></br>
              Av 24 De Julho, Bairro Central,No2021, R/C<br></br>
              NUIT:401041842<br></br><br></br>


              <h4> Contactos:</h4>
              www.ematronicslda@gmail.com
              Cell: <a href="#" className="call-us">+258 84 9640094</a>
            </h6>
          </div>
          <div class="footer-col">
            <h4>Empresa</h4>
            <ul>
              <li><Link to="./about">Quem somos</Link></li>
              <li><a href="#portifólio">Portifólio</a></li>
              <li><a href="#servicos">Serviços</a></li>

            </ul>
          </div>

          <div class="footer-col">
            <h4>Suporte</h4>
            <ul>
              <li><Link to="/contact">Contacte-nos</Link></li>
           
            </ul>
          </div>
          <div class="footer-col">
            <h4>Parceiros</h4>
            <div className="social-links">
              <a target="blank" href="https://www.nedbank.co.mz/"><img src={NedBank} alt="NedBank" /></a>   
              <a target="blank" href="https://www.nedbank.co.mz/"><img src={Parm} alt="NedBank" /></a>               
              <a  target="blank" href="https://web.facebook.com/fonseca.ceiling"><img src={Fonseca} alt="NedBank" /></a>
              <a  target="blank" href="#"><img src={Curule} alt="NedBank" /></a>  
              <a  target="blank" href="https://web.facebook.com/TIL-Construtora-Engenharia-e-Constru%C3%A7%C3%B5es-323816151075147/?_rdc=1&_rdr"><img src={Til} alt="NedBank" /></a>
            </div>
          </div>
        </div>
      </div>

      <div className="boderup">
        <div className="midia">
          <ul>

            <li><a target="blank" href="https://web.facebook.com/EMATRONICSlda-105415131871137" title="Facebook"><FacebookIcon className="insIcon"></FacebookIcon></a></li>
            <li><a target="blank" href="https://www.instagram.com/ematronics.lda/following/" title="Instagram"><InstagramIcon className="insIcon"></InstagramIcon></a></li>
            <li><a target="blank" href="https://twitter.com/EmatronicsLDADA" title="Twitter"><TwitterIcon className="insIcon"></TwitterIcon></a></li>
            <li><a target="blank" href="https://github.com/EMATRONICSLDA/EMATRONICSL" title="GitHub"><GitHubIcon className="insIcon"></GitHubIcon></a></li>
            <li><a target="blank" href="https://www.linkedin.com/in/ematronics-lda-b83b3721a/?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base%3BwqTWpcUIQXGmFJvWa7GMZQ%3D%3D" title="Linkedin"><LinkedInIcon className="insIcon"></LinkedInIcon></a></li>

          </ul>


        </div>



      </div>
    </footer>










  )
}

export default Footer;