import React from 'react';
import NavBar from '../Navbar/index';
import About from '../About/index'
import Services from '../Services/index'
import Portifolio from '../Portifolio/Index';
import Footer from '../Footer/index'
import Slider from '../../components/AboutDoc/SliderWorks/SlidesWork'

import './landing.css'

// import { Container } from './styles';

const Landing = () => {
  return (
    <>
      <div className="landing">
        <NavBar />
        <About />
        <Services />



      </div>




    </>

  )
}

export default Landing;