import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import logo from '../../assets/logo/logo.png'

import emailjs from 'emailjs-com'

import swal from 'sweetalert'

import { useHistory } from "react-router-dom";

import Footer from '../../screens/Footer'

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import './contactForm.css'
import { Navigation } from 'swiper';

import NedBank from '../../assets/img/ned1.png'
import Part from '../../assets/img/Part.jpg'
import Curule from '../../assets/img/curule.png'
import Fonseca from '../../assets/img/fos.png'
import Til from '../../assets/img/til.png'



import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone'
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';





const ContactForm = (props) => {
  const history = useHistory();

 
  function sendEmail(e) {
    
    e.preventDefault();



    emailjs.sendForm('service_0l6dib4', 'template_xwu8j4c', e.target, 'user_CBonBmsQZne2fGDrPG8Q9')

      .then((result) => {
        swal({
          title: "Obrigado por nos contactar",
          text: "O seu email sera respondido dentro de 24h!",
          icon: "success",
          button: 'OK' ,
        })
        

      }, (error) => {
        alert(error.message)

      });
    e.target.reset()


  }


  return (
    <>
      <div className="call-us">

        <Row>
          <div className="backbtn">
            <Link to="./">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          <Col xs={12} md={12} lg={6}>

            <div className="container">




              <div className="form">

                <div className="container">
                  <h1 className="text-center call">Contacte-nos</h1>

                  <form onSubmit={sendEmail}>
                    <div className="row pt-5 mx-auto">

                      <div className="col-lg-8 col-sm-12 form-group mx-auto">
                        <label>Nome completo</label>
                        <input type="text" autoFocus className="form-control" required placeholder="Escreva aqui o seu nome" name="name" />
                      </div>
                      <div className="col-lg-8 col-sm-12 form-group pt-1 mx-auto">
                        <label>Email</label>
                        <input type="email" className="form-control" required placeholder="Escreva aqui o seu email" name="email" />
                      </div>

                      <div className="col-lg-8 col-sm-12 form-group pt-1 mx-auto">
                        <label>Telefone</label>
                        <input type="tel" className="form-control"  required placeholder="Escreva aqui o seu contacto" name="contact"  />
                      </div>

                      <div className="col-lg-8 col-sm-12 form-group pt-1 mx-auto">
                        <label className="sev">Serviço</label>
                        <select name="service">
                          <option>Design</option>
                          <option>Programação</option>
                          <option>Cybersecurity</option>
                          <option>Engenharia informatica</option>
                          <option>Cloud Computing</option>
                          <option>Electronica</option>
                        </select>
                      </div>



                      <div className="col-lg-8 col-sm-12 form-group pt-1 mx-auto">
                        <label>Mensagem</label>
                        <textarea className="form-control" id="" cols="30" rows="8" required placeholder="Sua mensagem" name="message"></textarea>
                      </div>
                      <div className="col-lg-8 col-sm-12 pt-3 mx-auto">
                        <input type="submit" className="btn btn-info" value="Enviar mensagem"></input>
                      </div>
                    </div>
                  </form>
                </div>






              </div>

            </div>

          </Col>
          <Col xs={12} md={12} lg={6}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3587.074422056108!2d32.571493914657665!3d-25.965599483544555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ee69b02fe1fcc19%3A0xa9a5d4156c0223c7!2sMinistry%20of%20Labor%2C%20Employment%20and%20Social%20Security!5e0!3m2!1sru!2smz!4v1630078162658!5m2!1sru!2smz" width="100%" height="100%" style={{ border: 0 }} allowfullscreen="" loading="lazy"></iframe>
          </Col>
        </Row>

      </div>


      <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="footer-col">
            <h4>Localize-nos</h4>
            <h6>
              Maputo-Moçambique<br></br>
              Av 24 De Julho, Bairro Central,No2021, R/C<br></br>
              NUIT:401041842<br></br><br></br>


              <h4> Contactos:</h4>
              www.ematronicslda@gmail.com
              Cell: <a href="#" className="call-us">+258 84 9640094</a>
            </h6>
          </div>
          <div class="footer-col">
            <h4>Empresa</h4>
            <ul>
              <li><Link to="./about">Quem somos</Link></li>
              <li><a href="/">Portifólio</a></li>
              <li><a href="/">Serviços</a></li>

            </ul>
          </div>

          <div class="footer-col">
            <h4>Suporte</h4>
            <ul>
              <li><Link to="/contact">Contacte-nos</Link></li>
            </ul>
          </div>
          <div class="footer-col">
            <h4>Parceiros</h4>
            <div className="social-links">
              <a target="blank" href="https://www.nedbank.co.mz/"><img src={NedBank} alt="NedBank" /></a>
              <a href="#"><img src={Part} alt="NedBank" /></a>
              <a href="#"><img src={Curule} alt="NedBank" /></a>    
              <a  target="blank" href="https://web.facebook.com/fonseca.ceiling"><img src={Fonseca} alt="NedBank" /></a>
              <a  target="blank" href="https://web.facebook.com/TIL-Construtora-Engenharia-e-Constru%C3%A7%C3%B5es-323816151075147/?_rdc=1&_rdr"><img src={Til} alt="NedBank" /></a>
            </div>
          </div>
        </div>
      </div>

      <div className="boderup">
        <div className="midia">
          <ul>

            <li><a target="blank" href="https://web.facebook.com/EMATRONICSlda-105415131871137" title="Facebook"><FacebookIcon className="insIcon"></FacebookIcon></a></li>
            <li><a target="blank" href="https://www.instagram.com/ematronics.lda/following/" title="Instagram"><InstagramIcon className="insIcon"></InstagramIcon></a></li>
            <li><a target="blank" href="https://twitter.com/EmatronicsLDADA" title="Twitter"><TwitterIcon className="insIcon"></TwitterIcon></a></li>
            <li><a target="blank" href="https://github.com/EMATRONICSLDA/EMATRONICSL" title="GitHub"><GitHubIcon className="insIcon"></GitHubIcon></a></li>
            <li><a target="blank" href="https://www.linkedin.com/in/ematronics-lda-b83b3721a/?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base%3BwqTWpcUIQXGmFJvWa7GMZQ%3D%3D" title="Linkedin"><LinkedInIcon className="insIcon"></LinkedInIcon></a></li>

          </ul>


        </div>



      </div>
    </footer>



    </>


  )
}

export default ContactForm;