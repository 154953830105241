import React from 'react'
import { Link } from 'react-router-dom'

import './styles.css'
import { Navbar, Nav, Container } from 'react-bootstrap'
import Logo from '../../assets/logo/logo1.png'


import back from '../../assets/img/aws.png'





export default function Index() {
  return (
    <>
      <header className="header_area">
        <Navbar expand="lg" className="navBarContent">
          <Link to="/">
            <Navbar.Brand>
              <header>
                <div class="logo">
                  <img src={Logo} alt="Logo" />
                </div>
              </header>
            </Navbar.Brand>
          </Link>

          <Navbar.Toggle aria-controls="navbarScroll" className="button" />
          <Navbar.Collapse className="justify-content-end  menu" id="navbarScroll">
            <Nav
              className="mr-auto my-2 my-lg-0   navbar-expand-lg "
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              <Nav.Link href="#home" className="link"><Link to="/about">Quem somos</Link></Nav.Link>
              <Nav.Link href="#portifólio" className="link">Portfólio</Nav.Link>
              <Nav.Link href="#servicos" className="link">Serviços</Nav.Link>
              <Nav.Link href="#pricing" className="link"><Link to="/contact">Contacte-nos</Link></Nav.Link>


            </Nav>

          </Navbar.Collapse>
        </Navbar>



      </header>






    </>
  )
}
