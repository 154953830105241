import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import AboutUs from './components/AboutDoc/Index'
import ContactUs from './components/Contact/Index'
import Landing from './screens/Landing/Index'

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Landing} />
        <Route path="/about" exact component={AboutUs} />
        <Route path="/contact" exact component={ContactUs} />
      </Switch>
    </BrowserRouter>

  )
}

export default Routes;