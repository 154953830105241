import React, { useState } from 'react';
import { Container, Row, Col, Card, Carousel } from 'react-bootstrap'
import Working from '../../assets/logo/logob.png'
import './About.css'
import fontawesome from '@fortawesome/react-fontawesome';
import Ban1 from '../../assets/banner/ban1.jpg'
import Ban from '../../assets/banner/ban.jpg'
import Ban3 from '../../assets/banner/ban3.jpg'
import Logo from '../../assets/logo/logob.png'

import { Link } from 'react-router-dom'

import Slider from '../../components/AboutDoc/SliderWorks/SlidesWork'


import { motion } from 'framer-motion'
import Portifolio from '../Portifolio/Index';



const Works = () => {
  const title = {
    hidden: { opacity: 0, y: -100 },
    visible: { opacity: 1, y: 0 },

  }

  const teste = {
    hidden: { opacity: 1, x: -100 },
    visible: { opacity: 1, x: 0 },


  }


  const image = {
    hidden: { opacity: 0, y: 0 },
    visible: { opacity: 1, y: 0 },


  }


  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <main className="site-mainAbout">
        <section className="site-banner">
          <div className="container">
            <Row>
              <Col>
                <div className="sobreContent">





                  <motion.div
                    variants={image}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 2 }}
                  >



                    <div className="logob">
                      <img src={Logo} />
                    </div>

                  </motion.div>
                  <div className="bols justify-content-end">

                  </div>


                  <Container>
                    <div className="text">
                      <motion.div
                        variants={title}
                        initial="hidden"
                        animate="visible"
                        transition={{ duration: 3 }}

                      >

                        <p> <span>A EMATRONICS </span>
                          é uma empresa de consultoria informática criada com o propósito de
                          melhorar as funcionalidades dos sistemas informáticos envolvidos na gestão da sua
                          empresa.<br></br>

                          <Link to="/about" className="readmoreUs">Conheça-nos</Link>


                        </p>



                      </motion.div>








                    </div>




                  </Container>















                </div>


              </Col>
            </Row>


          </div>
          <Portifolio />

        </section>


      </main>





    </>


  )
}

export default Works;