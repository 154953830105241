import React from 'react';
import { Col, Row, Container, Carousel, Card, CardGroup } from 'react-bootstrap'

import './portfolio.css'

import S8 from '../../assets/banner/ban5.jpg'
import S2 from '../../assets/banner/mobileApp.jpg'
import S3 from '../../assets/banner/design.jpg'


// import { Container } from './styles';

const Portifolio = () => {
  return (
    <main className="site-mainOurWork">
      <section className="site-banner">

        <div className="ourjobsE" id="portifólio">
          <Container>
            <h3 className="text-center">O QUE FAZEMOS</h3>
            <div class="border"></div>




            <Row>

              <Col sm>
                <div className="site-title">


                  <Carousel>
                    <Carousel.Item interval={18000}>
                      <div className="textOurWork">
                        <p>
                          <span className="titlePort">Consultoria & Monitoria</span><br></br>
                          Em ambientes virtuais,
                          casos os nossos clientes ainda tenham infraestruturas
                          on-premise, é preciso optimizar os seus serviços garantindo a alta disponibilidade,
                          balanceamento de cargas etc...A <b>Ematronics</b> oferece monitoria centralizada e
                          resolução prévia á incidentes.Com auxílio de algumas parcerias como:
                          <ul className="portlist">
                            <li>Dynatrace; </li>
                            <li>Grafana; </li>
                            <li>Zabbix.</li>
                          </ul>



                        </p>




                      </div>


                    </Carousel.Item>
                    <Carousel.Item interval={18000}>
                      <div className="textOurWork">
                        <p>
                          <span className="titlePort">Service Desk</span><br></br>
                          A Ematronics tem também
                          uma visão em seus clientes que precisam de um auxílio
                          em seu ambiente de trabalho periodicamente, e com
                          essa visão temos técnicos especializados 24/7
                          disponiveis em tempo útil para dar assistência técnica ao domicílio
                          a sua empresa, seja essa assistência remota ou
                          presencial. Somos Orientados pelas
                          mais recentes plataformas de gestão de pedidos.
                          Obedencendo SLAs contratuais.
                          Tratamento para VIPs.
                        </p>

                      </div>


                    </Carousel.Item>
                    <Carousel.Item interval={18000} >
                      <div className="textOurWork">

                        <p>
                          <span className="titlePort">Design & Aplicações </span><br></br>
                          A <b>Ematronics</b> faz a criação de layout/interface <b>"UX/UI" </b> usando práticas padrão de <b>HTML/CSS</b> e <b> Bibliotecas</b>.
                          Integração de dados de vários serviços de back-end, API's e bases de dados.
                          Reunimos e refinamos especificações e requisitos com base em necessidades técnicas do cliente
                          Criamos e mantemos documentação de softwares
                          Somos responsáveis pela manutenção, expansão e escalabilidade de nosso serviços:
                          <ul>
                            <li>Web Design;</li>
                            <li>Graphic Design;</li>
                            <li>Aplicações mobile;</li>
                            <li>E-Commerce & Dropshipping.</li>
                          </ul>


                        </p>
                      </div>



                    </Carousel.Item>
                  </Carousel>

                </div>
              </Col>

              <Col sm>
                <div className="portImg">

                  <Carousel>
                    <Carousel.Item interval={18000}>
                      <img
                        className="d-block w-100"
                        src={S8}
                        alt="First slide"
                      />

                    </Carousel.Item>
                    <Carousel.Item interval={18000}>
                      <img
                        className="d-block w-100"
                        src={S3}
                        alt="Second slide"
                      />

                    </Carousel.Item>
                    <Carousel.Item interval={18000}>
                      <img
                        className="d-block w-100"
                        src={S2}
                        alt="Third slide"
                      />

                    </Carousel.Item>
                  </Carousel>
                </div>
              </Col>
            </Row>
          </Container>


        </div>


      </section>

    </main >



  );
}

export default Portifolio;